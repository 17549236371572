import React from 'react'
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Col,
  Row
} from 'reactstrap'
import DatePicker from 'react-datepicker'
import is from 'is_js'
import NumberFormat from 'react-number-format'

function AddonInput(props) {
  const { icon, ...restProps } = props
  return (
    <InputGroup>
      <Input {...restProps} />
      <InputGroupAddon addonType="append">
        <Button type="button" onClick={props.onClick}>
          <i className={icon} />
        </Button>
      </InputGroupAddon>
    </InputGroup>
  )
}

export function SearchInput(props) {
  return <AddonInput icon="fa fa-search" type="search" {...props} />
}

export function SelectionInput(props) {
  const { onClick, onClear, onCreate, icon, ...restProps } = props
  return (
    <InputGroup>
      <Input onChange={() => {}} onClick={onClick} readOnly {...restProps} />
      {onCreate ? (
        <InputGroupAddon addonType="append">
          <Button onClick={onCreate}>
            <i className={icon ? icon : 'fa fa-plus'} />
          </Button>
        </InputGroupAddon>
      ) : null}
      <InputGroupAddon addonType="append">
        {onClear && restProps.value ? (
          <Button onClick={onClear}>
            <i className="fa fa-remove" />
          </Button>
        ) : (
          <Button onClick={onClick}>
            <i className={icon ? icon : 'fa fa-ellipsis-h'} />
          </Button>
        )}
      </InputGroupAddon>
    </InputGroup>
  )
}

export function NumberInput(props) {
  const { onChange, ...rest } = props
  return (
    <NumberFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      onValueChange={values => {
        const e = {
          target: {
            value: values.value
          }
        }
        onChange(e)
      }}
      className="text-right"
      {...rest}
    />
  )
}

export function AbsoluteNumberInput(props) {
  return (
    <NumberFormat
      customInput={Input}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      allowLeadingZeros={false}
      className="text-right"
      {...props}
    />
  )
}

export function AbsoluteNumberInputLegacy(props) {
  const min = props.min ? props.min : 0
  return (
    <Input
      type="number"
      min={min}
      {...props}
      onChange={e => {
        const val = e.target.value.replace(
          /^(0+)(\d{1,})/,
          (match, zeros, digits) => digits
        )

        e.target.value = val < 0 ? 0 : val

        props.onChange(e)
      }}
      onBlur={e => {
        const numval = +e.target.value
        if (is.not.number(numval) || numval < min) {
          e.target.value = min
        }

        props.onChange(e)
      }}
    />
  )
}

export function DateInput(props) {
  const { invalid, ...restProps } = props
  return (
    <DatePicker
      dateFormat="dd-MM-yyyy"
      customInput={<AddonInput invalid={invalid} icon="fa fa-calendar" />}
      className="d-block"
      {...restProps}
    />
  )
}

export function DateRange(props) {
  const {
    selectedStartDate,
    onChangeStartDate,
    startDate,
    endDateStart,
    selectedEndDate,
    onChangeEndDate,
    endDate,
    minDate,
    invalid,
    ...restProps
  } = props
  return (
    <Row>
      <Col md="6">
        <div className="mb-3">
          <DatePicker
            dateFormat="dd-MM-yyyy"
            className="d-block"
            customInput={<AddonInput invalid={invalid} icon="fa fa-calendar" />}
            onChange={date => {
              if (date > endDate) {
                // prevent start date to exceed end date
                onChangeEndDate(date)
              }

              onChangeStartDate(date)
            }}
            selected={selectedStartDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            {...restProps}
          />
        </div>
      </Col>
      <Col md="6">
        <div className="mb-3">
          <DatePicker
            dateFormat="dd-MM-yyyy"
            className="d-block"
            customInput={<AddonInput invalid={invalid} icon="fa fa-calendar" />}
            onChange={onChangeEndDate}
            selected={selectedEndDate}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            {...restProps}
          />
        </div>
      </Col>
    </Row>
  )
}
