import React from 'react'

export default function NewlineText(props) {
  const { children, maxLine, onViewMore } = props
  if (typeof children !== 'string') {
    return children
  }
  const lines = children.split('\n')
  const withNewLine = lines.map((line) => <p className="mb-0">{line}</p>)
  const slicedByMaxLine = !!maxLine
    ? withNewLine.slice(0, maxLine)
    : withNewLine
  return (
    <div className="d-flex justify-content-start align-items-end">
      <div>{slicedByMaxLine}</div>
      {!!maxLine && onViewMore && lines.length > 3 && (
        <div className="text-primary clickable ml-3" onClick={onViewMore}>
          View more
        </div>
      )}
    </div>
  )
}
