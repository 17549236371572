import React from 'react'
import { FormFeedback } from 'reactstrap'

function FormError(props) {
  const { errors, ...restProps } = props

  if (!errors || errors.length < 1) {
    return null
  }

  return errors.map(err => (
    <FormFeedback key={err} {...restProps}>
      {err}
    </FormFeedback>
  ))
}

export default FormError
