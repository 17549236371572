import axios from 'axios'

const successInterceptor = x => x

const errorInterceptor = error => {
  const { status } = error.response
  const { type } = error.response.data
  if (status === 401 && type !== 'login_error') {
    window.localStorage.clear()
    window.location.reload()
    return
  }

  throw error
}

axios.interceptors.response.use(successInterceptor, errorInterceptor)
