import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './App.scss'
import 'toasted-notes/src/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import './style.css'

import './axiosSetup'

import { AuthProvider } from './views/auth/auth-context'
import { Loading } from './views/common/components'

// Containers
const Layout = React.lazy(() => import('./views/common/components/Layout'))

// Pages
const LoginPage = React.lazy(() => import('./views/auth/LoginPage'))
const Page404 = React.lazy(() => import('./views/pages/Page404'))
const Page500 = React.lazy(() => import('./views/pages/Page500'))

class App extends Component {
  render() {
    return (
      <AuthProvider>
        <HashRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={props => <LoginPage {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={props => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={props => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={props => <Layout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </AuthProvider>
    )
  }
}

export default App
