import React, { useState, useMemo, useContext } from 'react'

const tokenManager = {
  set(val) {
    window.localStorage.setItem('JOBSG_TOKEN', val)
  },
  get() {
    return window.localStorage.getItem('JOBSG_TOKEN')
  },
  remove() {
    window.localStorage.removeItem('JOBSG_TOKEN')
  }
}

const userManager = {
  set(user) {
    window.localStorage.setItem('JOBSG_ADMIN_USER', JSON.stringify(user))
  },
  get() {
    return JSON.parse(window.localStorage.getItem('JOBSG_ADMIN_USER'))
  },
  remove() {
    window.localStorage.removeItem('JOBSG_ADMIN_USER')
  }
}

const AuthContext = React.createContext()

function AuthProvider(props) {
  const [token, setToken] = useState(tokenManager.get())
  const [user, setUser] = useState(userManager.get())

  const value = useMemo(() => ({ user, setUser, token, setToken }), [
    user,
    token
  ])
  return <AuthContext.Provider value={value} {...props} />
}

function useAuth() {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw Error('useAuth must be used within AuthProvider')
  }
  const { user, token, setUser, setToken } = authContext

  const isLoggedIn = () => {
    return token !== '' && token !== null
  }
  const persistUser = user => {
    userManager.set(user)
    setUser(user)
  }
  const persistToken = token => {
    tokenManager.set(token)
    setToken(token)
  }
  const logout = () => {
    tokenManager.remove()
    userManager.remove()
    setToken('')
    setUser(null)
  }

  return {
    isLoggedIn,
    user,
    token,
    persistUser,
    persistToken,
    logout
  }
}

export { AuthProvider, useAuth }
